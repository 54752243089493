import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from './alert-dialog.component';
import { ButtonProps } from '../button/button.component';

export enum ALERT_DIALOG_TYPE {
  INFO = 'info',
  WARNING = 'warning',
  ACKNOWLEDGE = 'acknowledge'
};

export interface AlertDialogProps {
  dialogType: ALERT_DIALOG_TYPE,
  dialogTitle: { leading: { text: string, color?: string }, trailing?: { text: string, color?: string } },
  dialogMessage: string,
  dialogIcon?: string,
  actionButtonDisabled?: boolean,
  primaryButtonProps?: ButtonProps,
  secondaryButtonProps?: ButtonProps,
  onPrimaryButtonPressed: () => void
  onSecondaryButtonPressed?: () => void
}

@Injectable({
  providedIn: 'root'
})

export class AlertDialogService {

  constructor(public dialog: MatDialog) { }

  showAlertDialog(props: AlertDialogProps) {
    this.dialog.open<AlertDialogComponent>(AlertDialogComponent, {
      data: props
    })
  }

}