import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ButtonComponent, BUTTON_THEME } from '../button/button.component';
import { NgClass, NgStyle } from '@angular/common';
import { ALERT_DIALOG_TYPE, AlertDialogProps } from './alert-dialog.service';

@Component({
  selector: 'app-alert-dialog',
  standalone: true,
  imports: [
    ButtonComponent, 
    MatDialogModule, 
    NgStyle,
    NgClass
  ],
  templateUrl: './alert-dialog.component.html',
  styleUrl: './alert-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AlertDialogComponent {

  BUTTON_THEME = BUTTON_THEME;
  ALERT_DIALOG_TYPE = ALERT_DIALOG_TYPE;

  constructor(@Inject(MAT_DIALOG_DATA) public props: AlertDialogProps) { }

}